<template>
  <on-transaction-label
      :on-click="this.gotToYearlySubscriptionPage"
      callToAction="ספרו לי איך"
      :isCallToActionLink="true"
      variant="info">
    <template v-slot:label-text>
      <div>גם על המינוי לרייזאפ אפשר לחסוך!</div>
    </template>
  </on-transaction-label>
</template>
<script>

import Segment from '@/Segment';
// eslint-disable-next-line max-len
import OnTransactionLabel from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/OnTransactionLabel.vue';
import router from '@/router';

export default {
  name: 'RiseupMonthlySubscriptionLabel',
  components: { OnTransactionLabel },
  created() {
    Segment.trackUserGot('RiseupMonthlySubscriptionTransactionLabel_Shown');
  },
  methods: {
    gotToYearlySubscriptionPage() {
      Segment.trackUserGot('RiseupMonthlySubscriptionTransactionLabel_Clicked');
      router.push({ path: '/subscription/update' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

</style>

<template>
  <transaction-row-base :actualAmount="actualAmount"
                        :out-of-date="outOfDate"
                        :predictedAmount="predictedAmount"
                        :canEditEnvelope="canEditEnvelope"
                        :transactionDate="transactionDate"
                        :transactionDescription="transactionDescription"
                        :isSelected="isSelected"
                        :is-editable="isEditable"
                        :has-update="hasUpdate"
                        :is-bi-monthly-transaction="isBiMonthlyTransaction"
                        :is-postponed="isPostponed"
                        :customer-comment="customerComment"
                        :payment-app-details="paymentAppDetails"
                        :transaction="predictedTransaction.actual"
  >
    <template v-slot:on-transaction-label>
      <papa-investigation-label v-if="papaInvestigation" :papa-investigation="papaInvestigation"/>
      <riseup-monthly-subscription-label v-if="shouldDisplayRiseupYearlySubscriptionLabel"/>
    </template>
  </transaction-row-base>
</template>

<script>
import _ from 'lodash';
import PapaInvestigationLabel
// eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/PapaInvestigationLabel.vue';
import { mapGetters } from 'vuex';
import TransactionDisplayUtils from '../../TransactionDisplayUtils.vue';
import TransactionRowBase from './TransactionRowBase';
import RiseupMonthlySubscriptionLabel from './RiseupMonthlySubscriptionLabel.vue';

const RISEUP_MONTHLY_SUBSCRIPTION = {
  name: ['riseup', 'רייזאפ'],
  amount: [50, 45],
};

export default {
  name: 'PredictedTransactionRow',
  components: {
    RiseupMonthlySubscriptionLabel,
    TransactionRowBase,
    PapaInvestigationLabel,
  },
  mixins: [TransactionDisplayUtils],
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
    canEditEnvelope: {
      type: Boolean,
      required: false,
      default: true,
    },
    showPrediction: {
      type: Boolean,
      required: false,
      default: true,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
    papaInvestigation: {
      type: Object,
      required: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
    },
    hasUpdate: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('subscription', ['isYearlySubscription']),
    customerComment() {
      return this.predictedTransaction.sequenceCustomerComment || this.predictedTransaction.actual?.customerComment;
    },
    paymentAppDetails() {
      return this.hasActual ? this.getPaymentAppDetails(this.predictedTransaction.actual) : undefined;
    },
    hasActual() {
      return this.predictedTransaction.actual;
    },
    actualAmount() {
      if (this.hasActual) {
        return (
          this.predictedTransaction.actual.billingAmount
            ? this.predictedTransaction.actual.billingAmount
            : this.predictedTransaction.actual.incomeAmount
        ) ?? 0;
      }
      return 0;
    },
    isPostponed() {
      return this.hasActual ? this.predictedTransaction.actual.isPostponed : false;
    },
    predictedAmount() {
      return this.predictedTransaction.predictedAmount;
    },
    transactionDate() {
      if (this.hasActual) {
        return this.predictedTransaction.actual.transactionDate;
      }

      return '';
    },
    actualBusinessName() {
      return this.predictedTransaction.actual.businessName;
    },
    transactionPayment() {
      return this.getTransactionPayment(this.predictedTransaction.actual);
    },
    transactionAccount() {
      return this.getTransactionAccount(this.predictedTransaction.actual);
    },
    transactionDescription() {
      if (this.hasActual) {
        return `${this.actualBusinessName} ${this.transactionPayment} ${this.transactionAccount}`;
      }
      return this.predictedTransaction.name;
    },
    isBiMonthlyTransaction() {
      return this.predictedTransaction?.actual?.monthsInterval === 2;
    },
    shouldDisplayRiseupYearlySubscriptionLabel() {
      return !this.isYearlySubscription
          && RISEUP_MONTHLY_SUBSCRIPTION.amount.includes(this.predictedAmount)
          && _.some(RISEUP_MONTHLY_SUBSCRIPTION.name, word => _.includes(this.transactionDescription.toLocaleLowerCase(), word));
    },
  },
};
</script>
<style scoped lang="scss">

</style>

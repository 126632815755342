<template>
  <div class="predicted-transaction-container" tabindex="-1">
    <div role="button"
         tabindex="0"
         @click="canEditEnvelope && selectTransaction()"
         @keyup.enter.stop="canEditEnvelope && selectTransaction()">
      <predicted-transaction-row :predicted-transaction="predictedTransaction" :can-edit-envelope="canEditEnvelope"
                                 :out-of-date="outOfDate" :category-name="categoryName"
                                 :papa-investigation="papaInvestigation" :is-editable="canEditEnvelope" :has-update="hasUpdate"/>
    </div>
  </div>
</template>

<script>
import Segment from '@/Segment';
import { mapActions, mapGetters } from 'vuex';

import TransactionMenu from './TransactionMenu';
import transactionUtils from '../../../../../../../../../utils/transaction';
import PredictedTransactionRow from './PredictedTransactionRow.vue';
// eslint-disable-next-line max-len
const ChangeTransactionCategoryPopup = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/ChangeTransactionCategoryPopup');
// eslint-disable-next-line max-len
const MovePredictionToSaving = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-saving-transaction/MovePredictionToSaving');
// eslint-disable-next-line max-len
const MoveSavingPredictionToPrediction = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-saving-transaction/MoveSavingPredictionToPrediction');
// eslint-disable-next-line max-len
const MovePredictionWithActualToSaving = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-saving-transaction/MovePredictionWithActualToSaving');
// eslint-disable-next-line max-len
const MoveSavingPredictionWithActualToOneTimeSaving = () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-saving-transaction/MoveSavingPredictionWithActualToOneTimeSaving');
// eslint-disable-next-line max-len
const EditPredictedWithActualFlow = () => import('../../../current-month/components/cashflow-edit/edit-transaction/edit-predicted-transaction/flows/EditPredictedWithActualFlow.vue');
// eslint-disable-next-line max-len
const EditPredictedWOActualFlow = () => import('../../../current-month/components/cashflow-edit/edit-transaction/edit-predicted-transaction/flows/EditPredictedWOActualFlow.vue');
// eslint-disable-next-line max-len
const EditEmptyEnvelopeFlow = () => import('../../../current-month/components/cashflow-edit/edit-transaction/edit-predicted-transaction/flows/EditEmptyEnvelopeFlow.vue');
const AddTransactionComment = () => import('../../../current-month/components/cashflow-edit/edit-transaction/AddTransactionComment');

export default {
  name: 'PredictedTransaction',
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
    canEditCategory: {
      type: Boolean,
      required: true,
    },
    hasUpdate: {
      type: Boolean,
      default: false,
    },
    categoryName: {
      type: String,
      required: false,
    },
    categoryId: {
      type: String,
      default: '',
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PredictedTransactionRow,
  },
  computed: {
    ...mapGetters('mergeInvestigationQuestions', ['classifiedMergeInvestigation']),
    canEditEnvelope() {
      return this.canEditCategory && !this.papaInvestigation;
    },
    incomeOrExpense() {
      return transactionUtils.incomeOrExpense(this.predictedTransaction);
    },
    transactionId() {
      return this.predictedTransaction.actual?.transactionId;
    },
    idForUrl() {
      return this.transactionId || this.predictedTransaction.sequenceId;
    },
    papaInvestigation() {
      return this.predictedTransaction.actual ? this.classifiedMergeInvestigation(this.transactionId) : undefined;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('editTransaction', ['startEditingTransaction']),
    openEditPredictedTransaction(popupToOpen) {
      return () => {
        Segment.trackUserInteraction('EditPredictedTransactionClicked', { businessName: this.predictedTransaction.name });
        this.openModal({
          component: popupToOpen,
          props: {
            predictedTransaction: this.predictedTransaction,
          },
        });
      };
    },
    openEditCategory(transaction, component) {
      Segment.trackUserInteraction('EditTransactionClicked', { businessName: this.predictedTransaction.businessName });
      this.startEditingTransaction({
        transaction,
        categoryName: this.categoryName,
        categoryId: this.categoryId,
      });
      this.openModal({
        component,
        props: {
          predictedTransaction: true,
        },
      });
    },
    selectTransaction() {
      this.$router.push({
        query: {
          ...this.$route.query,
          transactionId: this.idForUrl,
        },
        params: this.$route.params,
      });
    },
    isBalanced() {
      return this.predictedTransaction.balanceDatePassed;
    },
    getShareActionText() {
      // Building the router state for a transaction, so we can have the link to the current state
      // but with the transaction id in the query params. Adding the origin, since the router doesn't
      // have the full URL, only the path.
      const transactionRoutePath = this.$router.resolve({
        name: this.$route.name,
        params: this.$route.params,
        query: { ...this.$route.query, transactionId: this.idForUrl },
      });
      const transactionLink = `${window.location.origin}${transactionRoutePath.href}`;
      return `היי, ראיתי את ה${this.incomeOrExpense} בתזרים ורציתי להתייעץ.%0A${encodeURIComponent(transactionLink)}`;
    },
    getShareActionPerMember() {
      /*
      Each time a component that uses computed properties (via mapGetters) is created,
      Vue sets up watchers on the component’s computed properties.
      This is not particularly expensive for a single component,
      but when a customer has many transactions it adds up and creates a performance bottleneck.
       */
      if (this.$store.getters['session/numberOfMembers'] === 1 || !this.$store.getters['featureFlags/enableShareTransaction']) {
        return [];
      }
      return this.$store.getters['session/otherMembers']?.map(member => {
        return {
          icon: 'share-with-member',
          onClick: () => {
            const WALinkWithPhoneNumber = `https://wa.me/${member.phone}?text=${this.getShareActionText()}`;
            Segment.trackUserInteraction('ShareTransactionClicked');
            window.open(WALinkWithPhoneNumber, '_blank');
          },
          text: `להתייעץ עם ${member.name} על ה${this.incomeOrExpense}`,
          showNewBadge: true,
        };
      });
    },
    getActions() {
      const actionsMapping = {
        editCategory: {
          text: `זו לא ${this.incomeOrExpense} קבועה`,
          icon: 'edits-move',
          onClick: () => this.openEditCategory(this.predictedTransaction.actual, ChangeTransactionCategoryPopup),
        },
        addComment: {
          text: (this.predictedTransaction.actual?.customerComment || this.predictedTransaction.sequenceCustomerComment)
            ? 'לערוך את ההערה' : 'להוסיף הערה',
          icon: 'comment',
          onClick: () => this.openEditCategory(this.predictedTransaction, AddTransactionComment),
        },
        updatePrediction: {
          icon: this.isBalanced() ? 'edits-trash' : 'edit-prediction',
          onClick: this.openEditPredictedTransaction(EditEmptyEnvelopeFlow),
          text: this.isBalanced() ? 'למחוק את הצפי' : 'לערוך או למחוק את הסכום הצפוי',
        },
        changeCategoryWithActual: {
          icon: 'edits-sub-category',
          onClick: this.openEditPredictedTransaction(EditPredictedWithActualFlow),
          text: `זו ${this.incomeOrExpense} קבועה מסוג אחר`,
        },
        changeCategoryWOActual: {
          icon: 'edits-sub-category',
          onClick: this.openEditPredictedTransaction(EditPredictedWOActualFlow),
          text: `זו ${this.incomeOrExpense} קבועה מסוג אחר`,
        },
        movePredictionToSaving: {
          icon: 'edits-is-savings',
          onClick: this.openEditPredictedTransaction(MovePredictionToSaving),
          text: 'זו הפקדה לחיסכון!',
        },
        moveActualToSaving: {
          icon: 'edits-is-savings',
          onClick: this.openEditPredictedTransaction(MovePredictionWithActualToSaving),
          text: 'זו הפקדה לחיסכון!',
        },
        notFixedSaving: {
          icon: 'edits-not-savings',
          onClick: this.openEditPredictedTransaction(MoveSavingPredictionToPrediction),
          text: 'זו לא הפקדה לחיסכון',
        },
        oneTimeSaving: {
          icon: 'savings-not-variable',
          onClick: this.openEditPredictedTransaction(MoveSavingPredictionWithActualToOneTimeSaving),
          text: 'זו הפקדה חד-פעמית לחיסכון',
        },
        shareWithMemberActions: this.getShareActionPerMember(),
      };
      const { actual, isIncome, isSaving } = this.predictedTransaction;
      return _getActions(!!actual, isIncome, isSaving, actionsMapping);
    },
    toggleActions() {
      this.openModal({
        component: TransactionMenu,
        props: {
          actions: this.getActions(),
          categoryName: this.categoryName,
          transaction: this.predictedTransaction,
          isPredictedTransaction: true,
        },
        popupAlignment: 'menu',
        eventHandlers: {
          close: this.removeTransactionFromUrl,
        },
      });
    },
    removeTransactionFromUrl() {
      if (this.$route.query.transactionId) {
        this.$router.push({ query: { ...this.$route.query, transactionId: undefined }, params: this.$route.params });
      }
    },
  },
};

function _getActions(hasActual, isIncome, isSaving, actionsMapping) {
  const actionsMap = _actionsByType(actionsMapping);
  if (isIncome) {
    if (!hasActual) {
      return actionsMap.fixedIncomePredicted;
    }
    return actionsMap.fixedIncomeActual;
  }

  if (isSaving) {
    if (!hasActual) {
      return actionsMap.fixedSavingPredicted;
    }
    return actionsMap.fixedSavingActual;
  }

  if (!hasActual) {
    return actionsMap.fixedExpensePredicted;
  }
  return actionsMap.fixedExpenseActual;
}

const _actionsByType = actionsMapping => {
  return {
    fixedIncomePredicted: [
      actionsMapping.updatePrediction,
      actionsMapping.changeCategoryWOActual,
      ...actionsMapping.shareWithMemberActions,
    ],
    fixedIncomeActual: [
      actionsMapping.addComment,
      actionsMapping.changeCategoryWithActual,
      actionsMapping.editCategory,
      ...actionsMapping.shareWithMemberActions,
    ],
    fixedSavingPredicted: [
      actionsMapping.addComment,
      actionsMapping.updatePrediction,
      actionsMapping.notFixedSaving,
      ...actionsMapping.shareWithMemberActions,
    ],
    fixedSavingActual: [
      actionsMapping.addComment,
      actionsMapping.oneTimeSaving,
      actionsMapping.notFixedSaving,
      ...actionsMapping.shareWithMemberActions,
    ],
    fixedExpensePredicted: [
      actionsMapping.addComment,
      actionsMapping.updatePrediction,
      actionsMapping.changeCategoryWOActual,
      actionsMapping.movePredictionToSaving,
      ...actionsMapping.shareWithMemberActions,
    ],
    fixedExpenseActual: [
      actionsMapping.addComment,
      actionsMapping.changeCategoryWithActual,
      actionsMapping.editCategory,
      actionsMapping.moveActualToSaving,
      ...actionsMapping.shareWithMemberActions,
    ],
  };
};

</script>

<style scoped lang="scss">
    @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .predicted-transaction-container {
    transition: background-color 0.3s;
    background-color: $riseup_gray_0;
    border-bottom: 1px solid $riseup_gray_0;
    &:first-of-type {
      box-shadow: inset 0 2px 3px 0 rgba(155, 155, 155, 0.51);
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
</style>

<script>

import { mapGetters, mapState } from 'vuex';
import DDLogs from '@/DDLogs';

const LOCAL_STORAGE_KEY_NTD = 'riseup.subscriptionNtdPopupDismiss';
export default {
  name: 'NoTrialDiscountMixin',
  computed: {
    ...mapState('benefits', ['benefitId']),
    ...mapGetters('featureFlags', ['noTrialDiscountIsOn']),
    ...mapGetters('timeLimitedOffers', ['noTrialDiscount']),
    ...mapGetters('subscriptionState', ['inTrial']),
    eligibleForNoTrialDiscountIsOn() {
      return this.noTrialDiscountIsOn && this.inTrial && !this.benefitId;
    },
    showNtdPopup() {
      return this.eligibleForNoTrialDiscountIsOn && this.noTrialDiscount;
    },
    noTrialDiscountOfferId() {
      return 'no-trial-discount';
    },
    noTrialDiscountValidHours() {
      return 48;
    },
    ntdDismissed() {
      return localStorage.getItem(LOCAL_STORAGE_KEY_NTD);
    },
  },
  methods: {
    ctaDismissNtd() {
      try {
        localStorage.setItem(LOCAL_STORAGE_KEY_NTD, JSON.stringify({ dismissDate: new Date() }));
      } catch (error) {
        DDLogs.error(error);
      }
    },
  },
};

</script>
